<template>
    <div>
        <div
            id="about-hero"
            class="my-5 py-20 overflow-hidden shadow-lg rounded-2xl hero"
            style="
            "
        >
            <div class="lg:px-20 p-5 text-left text-white">
                <h1 class="font-bold text-4xl">Tentang GenBI IPB</h1>
                <p class="py-8 lg:w-1/2 lg:leading-8 text-justify">
                    GenBI atau Generasi Baru Indonesia merupakan komunitas yang
                    diinisiasi oleh penerima Beasiswa Bank Indonesia. GenBI
                    terdapat di seluruh nusantara, salah satu diantaranya adalah
                    GenBI Institut Pertanian Bogor. GenBI IPB memiliki tiga
                    proyek utama yang bergerak di bidang pendidikan, lingkungan,
                    dan kesehatan.
                </p>
                <button class="font-black">Swipe ke bawah 👇</button>
            </div>
        </div>
        <!-- <div
            class="my-32 lg:mx-20 grid lg:grid-cols-2 md:grid-cols-1 gap-10 shadow-lg rounded-3xl"
        >
            <div class="lg:p-12 p-5 text-left">
                <h2
                    class="font-bold text-2xl mb-2"
                    :style="{ color: '#0D528D' }"
                >
                    Visi
                </h2>
                <p class="mt-8 leading-8">
                    Menjadikan kaum muda Indonesia sebagai generasi yang
                    kompeten dalam berbagai bidang keilmuan serta dapat membawa
                    perubahan positif dan menjadi inspirasi bagi bangsa dan
                    negara.
                </p>
            </div>
            <div class="lg:p-12 px-5 pb-5 text-left">
                <h2
                    class="font-bold text-2xl mb-2"
                    :style="{ color: '#0D528D' }"
                >
                    Misi
                </h2>
                <p class="mb-3 mt-8 leading-8">
                    <ul>
                        <li>
                           a. Menggagas berbagai kegiatan pemberdayaan masyarakat untuk Indonesia yang lebih baik (INITIATE)
                        </li>
                        <li>
                          b.  Menjadi garda terdepan dalam melakukan aksi nyata untuk pembangunan bangsa (ACT)
                        </li>
                        <li>
                            c. Peduli dan berkontribusi untuk pemberdayaan masyarakat (SHARE)
                        </li>
                        <li>
                            d. Berbagi inspirasi dan motivasi untuk menjadi energi bagi negeri (INSPIRE)
                        </li>
                    </ul>
                   
                </p>
            </div>
        </div> -->
        <!-- VISION STARTS HERE -->
        <div class="lg:m-20 my-5 m-4 my-28">
            <div class="flex flex-col md:flex-row">
                <div class="flex-1 md:text-left">
                    <h2
                        class="font-bold text-4xl md:text-left"
                        :style="{ color: '#0D528D' }"
                    >
                        Visi GenBI IPB
                    </h2>
                </div>
                <div
                    class="flex-1 md:text-right mt-8 md:mt-0 font-bold text-3xl"
                >
                    <h5>
                        Mewujudkan GenBI IPB yang memiliki solidaritas dan
                        berdampak
                    </h5>
                </div>
            </div>
            <!-- VISION ENDS HERE -->

            <div class="mt-24">
                <h2
                    class="font-bold text-4xl md:text-left"
                    :style="{ color: '#0D528D' }"
                >
                    Misi GenBI IPB
                </h2>
                <div
                    class="grid grid-flow-col grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-6 mt-8"
                >
                    <div
                        v-for="item in mission"
                        :key="item.title"
                        class="bg-blue-50 p-7 text-left flex flex-col rounded-md relative mt-16"
                    >
                        <img
                            :src="item.img"
                            alt=""
                            style="max-width: 80px; top:-20%"
                            class="absolute"
                        />
                        <p
                            class="text-3xl font-medium flex-1 mt-8"
                            :style="{ color: '#0D528D' }"
                        >
                            {{ item.title }}
                        </p>
                        <p class="mt-3 flex-1">
                            {{ item.description }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="mt-24">
                <h2
                    class="font-bold text-4xl text-left"
                    :style="{ color: '#0D528D' }"
                >
                    Tiga Proyek Utama Kami
                </h2>
                <div
                    class="py-8 grid lg:grid-cols-2 md:grid-cols-1 gap-10 items-center"
                >
                    <div class="flex justify-end">
                        <img
                            src="https://lh3.googleusercontent.com/ADWR5fGEvMvRxpHh9Jq0GXYZJfKHFF2w-aJYswOUnvFI95lP9hTvpq0cv4bBxJCZLmOzHH0FnAMx4yyCaSVzf9NS25WCY0LfELNFKBxrgFIZB8a90mKwcPcC9BUoWXOnIdg-1TLYjM5A-bCgQiaRvESp4lAOg47SKU6_9YetxkhmDPHReVtY9u42-PxJp2vDHIKkbMmvC6pYTvxQDI5XGBSLwwZYXgTln0L0CyyTZzNFhXI8ehTS8qCP716PnlMcsoupgyvqCgbC9G78IRnHIRUHMIOZbq0gGM9XIqKwq2-ydKJO5ZEuSVeBwm8hhuGZ3EjrTJEIZz6Im5k9jteCWxVJ24sp4LZYUf6_CkkczMvPFcp2be2VbuAFtTe127MHF_MYwJwrl7CNcXgjHhJ2Fz84k3VPsA8Q56b-xjUgFIFgScArhPttJ0Z6iYmvEj-1MDS0GlkONGtIN8qi0qYhGnbgjdY3wUK4fd_x0njAAI8VycltzKRyGSpmfVb2xo-2eQTIsMlxURWM9ZoVpVzW0uDwwrreISh-EwoT9-nOUgNhKbDdnQY4lOL85jE701G_Wf2okmBgzgeYpC_iKH9ibXkCy6mZxIU4ZyNpnORmcfN_hHszTpeIDUPR-dSkhFZRfTDR-wKXiY9gmerKbsT6APzQOHl63kotSi7wJ2OaOxd9C-Cp18wTPHL6VOf3hVPRONnYObYGTy7-V5FQxDDk5F7b"
                            alt="Img Proyek Lingkungan"
                            width="482"
                        />
                    </div>
                    <div class="text-left lg:order-first">
                        <h3
                            class="font-bold text-3xl"
                            :style="{ color: '#0D528D' }"
                        >
                            Lingkungan
                        </h3>
                        <p class="mt-8 leading-8">
                            Kami melakukan kegiatan sosial di lapangan maupun
                            mengedukasikan masyarakat tentang cara menjaga
                            lingkungan kita
                        </p>
                    </div>
                </div>
                <div
                    class="py-8 grid lg:grid-cols-2 md:grid-cols-1 gap-10 items-center"
                >
                    <div class="flex justify-start">
                        <img
                            src="https://lh3.googleusercontent.com/O6iIkE9pDQLgKkgtGLb3AAlnfCHFr0T40PjoRjlX1AQmQBWCnpXNUw4K-KzzcxEYEwWlqxBhtGVzIjsOW8WzCBVYFazFelp7PdghpfkRid16yW6sX9p09kqk1w_ODWEEwInkhvoeDwiCX4ZLg8IqDTyrLXf4BYLI2kWrCwlq8RgKRYUHqzFN-0w1gvewwSEKzDP0mpdcA7KKfmgt5PIBYlFudo2uH3oiORSo_9YGc0v4g7WHndzoaohG4pQoWdHlRrPqPa7Ahark9QZLNg2pcAnXKB_cIeap6MIAvvbwzovq49kvbCLhdQQxmtEl4_xqGgXt8farKumsHe11Dxr_M8pAAL8rGtdW1E_WD0WGgFfVHlG2Nz2w6Xfuf5JLto9dm2b-ruBdvRNGo-_K1H9h_AjIROov_WER2KdHAHuFhSy03n3LsmOrLktrbztEbKoRE5XpM16PFvn5SMOoF2andHEL5GfZ962gLNRf-JTlKXS4JPu5bs5idHqpYBdyZlpsP6tluYgLoCfAvu1-OHIoId2Gq8TGvzoQ8stKClMHH6slQQBNSCT6dTLtsLcqjDKWDSg3IhVtjGa64e5o8Yf1eMPqf2MP2_0AkqrFMvGLeyjE4kAOEOawO7JjwSDQh2eNs58hhYkZK5HojRR9WVXmwjk1pJ2utESmojGL8mYa4ejzf95Bc30nhTueCiY1eSETBnv0m6Ms58C2cfw2ERtpT7iv"
                            alt="Img Proyek Kesehatan"
                            width="453"
                        />
                    </div>
                    <div class="text-left">
                        <h3
                            class="font-bold text-3xl"
                            :style="{ color: '#0D528D' }"
                        >
                            Kesehatan
                        </h3>
                        <p class="mt-8 leading-8">
                            Kami melakukan kegiatan kesehatan seperti donor
                            darah maupun mengadakan acara kesehatan mental
                        </p>
                    </div>
                </div>
                <div
                    class="py-8 grid lg:grid-cols-2 md:grid-cols-1 gap-10 items-center"
                >
                    <div class="flex justify-end">
                        <img
                            src="https://lh3.googleusercontent.com/rWS5uRnQupczphxOEcXPuF8qxEpbDO1ukPtB5SnlRUZbybOO1QOu3QtDZcHeTj13yrnLDz_qyk9LKsmyeGfTnVN2rQIvoIgGK8iCVPfAfTJDDuXOY4BB3pO38sVhHx0mOowal_zqtlrKjetftpaAQFK0PzEGKnkd0mj_YpciWimxAn2LAWBU2kP-Ugk1d1amHY-dJWyAE8iTZmz2fHyD8q7K4OY6A0a49J6cYE7cckV8J0Yifgux0qxO5TcGc4PpT0_n-RG1ZNff1oEQvv22VotSDdgUgns787kZGqpMlrnbks_CrpEuLzRyzff_oqWatHM9CVTbeA2GloWaRKfnUEpATwO3ZYb6pK9fMltsMWNcw7fKh4EMy76FpTzHZ01bSly4VqdjBC3sZc0XJdQOitEmDrZGu2chzV_DpJZPkaYR5u_TUgkrLuysBPICbguLquCNR4rFqzciGBB7qKauxYKmrr2muyX8i1aJdEaa3u2UqGaqE0AuAHa6eWDVbTIyPaQtLOC4_WzKjRppThxqgcx0w6e23I-46MHEsun1Dp5UC-L6Un8klGWvPR2vyAGuMKzlLl4FlSlYdgpvcLgsN1V8Ugdrs5obloz05c-cbE5cGSJX2BHv_cx2qNghRnNrh_tr5ONkrLJAMuHanxNmpKE58YI7mtYh9cltgMIwRogH69mn2bjSyKCdLXteSRBikbctssj44lcfm2U0sPuDLAWx"
                            alt="Img Proyek Lingkungan"
                            width="482"
                        />
                    </div>
                    <div class="text-left lg:order-first">
                        <h3
                            class="font-bold text-3xl"
                            :style="{ color: '#0D528D' }"
                        >
                            Pendidikan
                        </h3>
                        <p class="mt-8 leading-8">
                            Kami mengadakan acara yang mengedukasikan masyarakat
                            tentang ekonomi, finansial pribadi, manajemen
                            keuangan, dan lain lain
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-24 bg-ready content-center overflow-hidden rounded-2xl">
            <h2
                class="lg:px-48 lg:pt-20 pt-16 pb-12 font-bold text-3xl"
                :style="{ color: '#0D528D' }"
            >
                Kamu siap memberikan energimu untuk membangun negeri bersama
                GenBI IPB?
            </h2>
            <button
                @click="$router.push('/anggota')"
                class="rounded-full px-5 py-2 bg-blue-500 hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-10 text-white"
            >
                Ya, saya siap!
            </button>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        window.scroll(0, 0)
    },
    data() {
        return {
            mission: [
                {
                    img: require('@/assets/icons/heart.png'),
                    title: 'Meningkatkan Internalisasi',
                    description:
                        'Meningkatkan internalisasi dan solidaritas antar pengurus dan anggota GenBI IPB',
                },
                {
                    img: require('@/assets/icons/hr.png'),
                    title: 'Meningkatkan SDM',
                    description: 'Meningkatkan SDM yang unggul dalam GenBI IPB',
                },
                {
                    img: require('@/assets/icons/impact.png'),
                    title: 'Program Yang Berdampak',
                    description:
                        'Menciptakan program yang berdampak bagi anggota GenBI IPB dan masyarakat luas',
                },
            ],
        }
    },
    metaInfo: {
        title: 'GenBI IPB',
        titleTemplate: `%s - Tentang kami`,
        htmlAttrs: {
            lang: 'id',
            amp: true,
        },
        meta: [
            { charset: 'utf-8' },
            {
                name: 'description',
                content:
                    'GenBI atau Generasi Baru Indonesia merupakan komunitas yang diinisiasi oleh penerima Beasiswa Bank Indonesia. GenBI terdapat di seluruh nusantara, salah satu diantaranya adalah GenBI Institut Pertanian Bogor.',
            },
            {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1',
            },
        ],
    },
}
</script>

<style>
.bg-ready {
    background-image: url('https://lh3.googleusercontent.com/4_sp0gKesvznH7_sr6_uWRL6x9_JQmFJAY1jBMNj4frqGbLSYciAV0v7cFLiSvs3502YXhJQjtI2HqhtUpVVYNwvmveDbZ4T8aMdTTUHMIeXN3ZN8B6FZ7ib_KCZIQxvRVoDLApd6_1DvlvCql5LWfU_EIFJa8lLFKocLgQwhEYjojr_ADldTqRNLfZq8JSG4tHuA7ypphS_Qx4WAPlY12ZVH7FYDkw_Da5dmTYv4PpNBjrUUufqAaWVCovKu1TKiR0aAHiWo34rZkTV0qr88MRXqYZ2o0FHDfLf30qBo9ZmSgj3UGPboELV4_auVEEZz1v1YHdlNR97S3BOmVSoDtm5QP7kS2aZIblwMi9Kl9P-yVSShqGQxICzh5gyjuNQBH1oxEPNXIFBBIx2F65yHcI_i6MolkelbpWsZhGOpwNEv9PUfhuR2DbIErEE52WOgNIMxZPG2oAa0BZZlyCoSQ1DR3Wm56RVGjyvg14QuvW1NsBf83IrI8_KyG2j6AmbQfigqdk8BUiUjLAQ-D-5xkIzfnU1I3NNmpDiFFHiLSK9_Ii5Z7Rr3xmQDJfHEXZlQ9Vcpf2iOBw99LlE4TH6iBhWrVJCWzx1N-jZEN82AbbYQU-_DKz-0FIwenOBH4gneqtw06sc6TnHcy8yHvpay3AoelzlbhPChsAzFG6uCC-QkblKnIjWFm9ue0PB5z_QqDiXJvVMl-4GZ8-W169JCGr9=w2400');
    background-size: 82.5rem;
    min-height: 320px;
    background-position: center;
    background-repeat: no-repeat;
}
#about-hero {
    background-image: linear-gradient(
            to left,
            rgba(255, 255, 255, 0),
            rgba(0, 0, 0, 0.8)
        ),
        url('https://lh3.googleusercontent.com/VpwGqE2W8aX0itpFYbCYwBGYFTQ7sLooe5uE-UkIGYi_7UnJVnyHHwuCjF5WE_xmc8O-EzINE1G2veiT5iomlCBChPzx2uPrYNyplFFEvCus2d3o7MKu9OTYO30Feu_Al84n-DPpDw=w2400');
}
</style>
